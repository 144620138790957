<template>
  <div class="imgBox min-w-1400">
    <div class="imgBox1 min-w-1400">
        <div class="img1Box max-w-1400">
          <img :src="importedImg1" alt="" class="imgItem1" />
        </div>
    </div>
    <div class="imgBox2 min-w-1400">
        <div class="img2Box max-w-1400">
          <img :src="importedImg2" alt="" class="imgItem2" />
        </div>
    </div>
    <div class="imgBox3 min-w-1400" @click="toggleNewImage(1)">
        <div class="img3Box max-w-1400">
          <img
          :src="showNewImage1 ? AnswerImg1 : importedImg3"
          :alt="altText"
          class="imgItem3"
        />
        </div>
    </div>
    <div class="imgBox4 min-w-1400">
        <div class="img4Box max-w-1400">
          <img :src="importedImg4" alt="" class="imgItem4" />
        </div>
    </div>
    <div class="imgBox5 min-w-1400">
        <div class="img5Box max-w-1400">
          <!-- <img :src="importedImg5" alt="" class="imgItem5" /> -->
        </div>
    </div>
    <!-- <div class="imgBox6 min-w-1400">
        <div class="img6Box max-w-1400">
          <img :src="importedImg6" alt="" class="imgItem6" />
        </div>
    </div> -->
    <!-- <div class="imgBox7 min-w-1400">
        <div class="img7Box max-w-1400">
          <img :src="importedImg7" alt="" class="imgItem7" />
        </div>
    </div> -->
  </div>
</template>

<script>
import "./index.css";
import importedImg1 from "@/page/margitcompany/components/img/1_1.png";
import importedImg2 from "@/page/margitcompany/components/img/1_2.png";
import importedImg3 from "@/page/margitcompany/components/img/1_3.png";
import importedImg4 from "@/page/margitcompany/components/img/1_4.png";
// import importedImg5 from "@/page/margitcompany/components/img/1_5.png";
// import importedImg6 from "@/page/margitcompany/components/img/1_6.png";
// import importedImg7 from "@/page/margitcompany/components/img/1_7.png";

import AnswerImg1 from "@/page/margitcompany/components/img/1_3_1.png";

export default {
  data() {
    return {
      importedImg1: importedImg1,
      importedImg2: importedImg2,
      importedImg3: importedImg3,
      importedImg4: importedImg4,
      // importedImg5: importedImg5,
      // importedImg6: importedImg6,
      // importedImg7: importedImg7,

      AnswerImg1: AnswerImg1,
      // AnswerImg2: AnswerImg2,
      // AnswerImg3: AnswerImg3,
      // AnswerImg4: AnswerImg4,
      // AnswerImg5: AnswerImg5,
      // AnswerImg6: AnswerImg6,
      // AnswerImg7: AnswerImg7,
      // AnswerImg8: AnswerImg8,

      altText: "图片",
      showNewImage1: false,
      showNewImage2: false,
      showNewImage3: false,
      showNewImage4: false,
      showNewImage5: false,
      showNewImage6: false,
      showNewImage7: false,
      showNewImage8: false,
      activeImage: 0,
    };
  },
  created() {
    this.gotoTop();
  },
  methods: {
    toggleNewImage(imageIndex) {
      //  console.log("点击了");
      //   this.$set(this.showNewImage, imageIndex, !this.showNewImage[imageIndex]);
      //   this.activeImage = imageIndex;
      console.log(this.showNewImage3);
      if (imageIndex == 1) {
        this.showNewImage1 = !this.showNewImage1;
      }
      if (imageIndex == 2) {
        this.showNewImage2 = !this.showNewImage2;
      }
      if (imageIndex == 3) {
        this.showNewImage3 = !this.showNewImage3;
      }
      if (imageIndex == 4) {
        this.showNewImage4 = !this.showNewImage4;
      }
      if (imageIndex == 5) {
        this.showNewImage5 = !this.showNewImage5;
      }
      if (imageIndex == 6) {
        this.showNewImage6 = !this.showNewImage6;
      }
      if (imageIndex == 7) {
        this.showNewImage7 = !this.showNewImage7;
      }
      if (imageIndex == 8) {
        this.showNewImage8 = !this.showNewImage8;
      }
      if (imageIndex == 9) {
        this.showNewImage9 = !this.showNewImage9;
      }
      if (imageIndex == 10) {
        this.showNewImage10 = !this.showNewImage10;
      }
      if (imageIndex == 11) {
        this.showNewImage11 = !this.showNewImage11;
      }
      if (imageIndex == 12) {
        this.showNewImage12 = !this.showNewImage12;
      }
      if (imageIndex == 13) {
        this.showNewImage13 = !this.showNewImage13;
      }
      if (imageIndex == 14) {
        this.showNewImage14 = !this.showNewImage14;
      }
      if (imageIndex == 15) {
        this.showNewImage15 = !this.showNewImage15;
      }
    },
    gotoTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },
};
</script>

<style scoped>
.imgBox {
  display: flex;
  flex-direction: column;
  background-color: white;
}
img {
  display: block;
}
.imgBox1,
.imgBox2,
.imgBox3,
.imgBox4,
.imgBox5,
.imgBox6,
.imgBox7 {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: "";
}

.img1Box,
.img2Box,
.img3Box,
.img4Box,
.img5Box,
.img6Box,
.img7Box {
  margin: 0;
  overflow: hidden;
  width: 100%;
  background-color: "";
}

.img2Box,.img4Box{
  padding: 80px 120px;
}


.imgItem1,
.imgItem2,
.imgItem3,
.imgItem4,
.imgItem5,
.imgItem6,
.imgItem7 {
  width: 100%;
  margin: 0;
}

</style>
